



















import { Component, Vue } from 'vue-property-decorator'
import AuthLayout from '@/layouts/AuthLayout.vue'
import Form from '@/components/form/Form.vue'
import { Routes } from '@/shared/router'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'

@Component({
  components: { AuthLayout, Form }
})
export default class ResetPassword extends Vue {
  Routes = Routes
  success = false

  form: FormBase = new FormBase()
    .setEndpoint('password/reset')
    .setFields([
      new Field()
        .setType(FieldType.password)
        .setKey('password')
        .setTitle('New password'),
      new Field()
        .setType(FieldType.password)
        .setKey('password_confirmation')
        .setTitle('Confirm new password'),
    ])
    .setInjectValues({
      email: this.$route.params.email,
      token: this.$route.params.token,
    })
    .setSubmit({
      text: 'Reset password',
      classes: 'd-flex justify-content-center align-items-center w-100 button-full-width larger-button'
    })
    .setOnSuccess(this.onSuccess)

  onSuccess() {
    this.success = true
  }
}
